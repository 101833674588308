/* global config, Promise */

'use strict';

angular.module('managerApp').controller('TabletPartnersController', (
        $scope, $mdDialog, toast, $_tablets, procedureTools, clients) => {

//------------------------------------------------------------------------------

    $scope.config = config;
    $scope.count = 0;
    $scope.items = [];
    $scope.types = [];
    $scope.newItem = {};

    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;

//------------------------------------------------------------------------------

    $scope.pagination = {
        currentPage: 1,
        maxSize: 2,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            $scope.getTabletPartners();
        }
    };

//------------------------------------------------------------------------------    

    $scope.getTabletPartners = function () {
        let params = {
            partners: 1
        };
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        $_tablets.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    }
    $scope.$on('get-tablet-partners', (e) => {
        $scope.pagination.filters = e.targetScope.search.filters;
        $scope.getTabletPartners();
    });
    $scope.getProcedureName = (procedureType) => {
        return procedureTools.getProcedureName(procedureType);
    };

    $scope.showAddEditPopup = ($event, item = null) => {
        $mdDialog.show({
            targetEvent: $event,
            clickOutsideToClose: false,
            parent: angular.element(document.body),
            templateUrl: 'app/tablets/partners/item.html',
            locals: {
                types: $scope.types,
                item: item
            },
            controller: ($scope, $mdDialog, $_tablets, clients, types, item) => {
                $scope.types = types;
                $scope.isEdit = item !== null;
                $scope.mode = null;
                $scope.item = item || {
                    commission: null,
                    acceptRCGPayments: true
                };
                if ($scope.isEdit) {
                    if ($scope.item.commission !== null) {
                        $scope.mode = 'commission';
                    } else {
                        $scope.mode = 'openPricing';
                    }
                }
                $scope.onModeSelect = () => {
                    $scope.item.commission = null;
                    if ($scope.mode === 'commission') {
                        $scope.item.acceptRCGPayments = true;
                    } else {
                        $scope.item.acceptRCGPayments = false;
                    }
                };
                $scope.getPartners = (name) => {
                    return clients.get({
                        type: 'partenaire',
                        name: name,
                        active: true
                    }).then((partners) => {
                        return Promise.resolve(partners.items);
                    }).catch((err) => {
                        return Promise.reject(err);
                    });
                };

                $scope.closeDialog = (closeOnly = false) => {
                    if (!closeOnly) {
                        $mdDialog.cancel();
                    } else {
                        if ($scope.item.partner) {
                            $scope.item.PartnerId = $scope.item.partner._id;
                        }
                        if ($scope.isEdit) {
                            let data = {
                                commission: $scope.item.commission,
                                acceptRCGPayments: $scope.item.acceptRCGPayments
                            };
                            $_tablets.update(data, $scope.item._id, true).then(() => {
                                $mdDialog.hide();
                                toast.show('Les données ont bien été mises à jour.', 'success');
                                $scope.getTabletPartners();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        } else {
                            $_tablets.add($scope.item, true).then(() => {
                                $mdDialog.hide();
                                toast.show('Le partenaire a bien été ajouté.', 'success');
                                $scope.getTabletPartners();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }
                    }//
                };
            }
        }).then((res) => {
        }).catch((err) => {
        });
    };

    $scope.showEditPricesPopup = (partner) => {
        $mdDialog.show({
            templateUrl: 'app/terminals/partners/edit.prices.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                partner: partner
            },
            controller: ($scope, toast, partner) => {
                $scope.procedureTypes = procedureTools.getAllProcedureTypes();
                $scope.partner = partner;
                $scope.rows = [];
                for (let i = 0; i < $scope.procedureTypes.length; i++) {
                    let p = $scope.procedureTypes[i];
                    let row = {
                        procedureLabel: p.label,
                        procedure: p.name
                    };
                    for (let j = 0; j < partner.Prices.length; j++) {
                        let price = partner.Prices[j];
                        if (p.name === price.procedure) {
                            row.price = price.price;
                            row._id = price._id;
                        }
                    }
                    $scope.rows.push(row);
                }

                $scope.savePrice = (procedurePrice) => {
                    let price = Object.assign({}, procedurePrice);
                    if (procedurePrice._id) {
                        for (let i in price) {
                            if (['_id', 'price'].indexOf(i) < 0) {
                                delete price[i];
                            }
                        }
                        if (price.price === '') {
                            price.price = null;
                        } else {
                            price.price = parseFloat(price.price);
                        }
                        $_tablets.updatePartnerProcedurePrice($scope.partner._id, price).then((updatedPrice) => {
                            if (updatedPrice.deleted === true) {
                                delete procedurePrice._id;
                                setTimeout(() => {
                                    $scope.$apply();
                                }, 50);
                            }
                            toast.show('Le prix de la démarche a bien été enregistré.', 'success');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    } else {
                        price.b2b = false;
                        price.deviceType = 'borne';
                        $_tablets.createPartnerProcedurePrice($scope.partner._id, price).then((newPrice) => {
                            procedurePrice._id = newPrice._id;
                            setTimeout(() => {
                                $scope.$apply();
                            }, 50);
                            toast.show('Le prix de la démarche a bien été enregistré.', 'success');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    }
                };

                $scope.closeDialog = () => {
                    $mdDialog.hide();
                    $scope.getTabletPartners();
                };
            }
        }).then((answer) => {
            console.log('answer : ' + answer);
        }, () => {
            console.log('You cancelled the dialog.');
        });
    };

    $scope.toggleActive = (item) => {
        let msg = 'Voulez-vous vraiment ' + (item.active ? 'dés' : '') + 'activer ce compte partenaire ?';
        if (item.active === true) {
            msg += "\n\n" + "Ne touchez pas cette configuration à moins d'être pleinement conscient de ce que vous faites !";
        }
        if (confirm(msg) === true) {
            $_tablets.update({active: !item.active}, item._id, true).then(() => {
                item.active = !item.active;
                toast.show('Le compte partenaire a bien été mis à jour.', 'success');
            }).catch(() => {
                toast.show('Le compte partenaire n\'a pas pu être mis à jour.', 'error');
            });
        }
    };

    $scope.__showAddEditPopup = ($event, item = null) => {
        $mdDialog.show({
            targetEvent: $event,
            clickOutsideToClose: false,
            parent: angular.element(document.body),
            templateUrl: 'app/tablets/partners/item.html',
            locals: {
                types: $scope.types,
                item: item
            },
            controller: ($scope, $mdDialog, $_tablets, clients, types, item) => {

                $scope.types = types;
                $scope.isEdit = item !== null;
                $scope.item = item || {withCommission: false};
                if ($scope.isEdit && $scope.item.commission) {
                    $scope.item.withCommission = true;
                }

                $scope.getPartners = (name) => {
                    return clients.get({
                        type: 'partenaire',
                        name: name,
                        active: true
                    }).then((partners) => {

                        console.log('get partners :');
                        console.log(partners);

                        return Promise.resolve(partners.items);
                    }).catch((err) => {
                        return Promise.reject(err);
                    });
                };

                $scope.closeDialog = (validated = false) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if ($scope.item.partner) {
                            $scope.item.PartnerClientId = $scope.item.partner._id;
                            delete $scope.item.partner;
                        }
                        if ($scope.isEdit) {
                            let data = {
                                commission: $scope.item.commission
                            };
                            $mdDialog.hide($_tablets.update(data, $scope.item._id, true));
                        } else {
                            $mdDialog.hide($_tablets.add($scope.item, true));
                        }
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le partenaire tablette a bien été ajouté.', 'success');
                $scope.getTabletPartners();
            }
        }).catch((err) => {
            if (err) {
                toast.show(err && err.message ? err.message : err, 'error', true);
            }
        });
    };

    $scope.getPartners = (name) => {
        return getPartners(name);
    };

    function getPartners(name) {
        return clients.get({
            type: 'partenaire',
            name: name,
            active: true
        }).then((partners) => {
            return Promise.resolve(partners.items);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    $scope.showDeleteConfirm = ($event, item) => {
        $mdDialog.show({
            templateUrl: 'app/tablets/delete.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            targetEvent: $event,
            locals: {
                itemId: item._id
            },
            controller: ($scope, $mdDialog, toast, $_tablets, itemId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        $_tablets.delete(itemId, 'partners').then(() => {
                            toast.show('Le compte partenaire a bien été supprimé.', 'success');
                            $scope.getTabletPartners();
                        }).catch((err) => {
                            toast.show(err);
                        });
                    }//
                };
            }
        });
    };

    $scope.getTabletPartners();

});
